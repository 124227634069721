import React,{useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSaldosEmpresa, setIdCredito } from '../actions'
import { creditos } from "../services/Credito"
import  avatar_gril from  "../assetss/img/avatar_gril.png"
import  avatar_men from  "../assetss/img/avatar_men.png"
import { toast, alerta, formatearMonedaValue } from "../services/utilities"
import ModalGenerarToken from './Creditos/ModalGenerarToken';

function Creditos(props) {

    useEffect(() => {
        handleConsultarCreditos()
    }, []);

    const [filter, setFilter] = useState('');
    const [filtro, setFiltro] = useState({
        "fechas":false,
        "fecha_ini": "",
        "fecha_fin": "",
        "estado_id":""
    });

    const navigate = useNavigate();
    const [spiner, setSpiner] = useState(false);
    const [tabla, setTabla] = useState([]);

    const handleBuscarIdCredito = async (id_credito) => {
        props.setIdCredito(id_credito);
        navigate('/credito');        
    }

    const handleInputChange = (event) => {

        const { name, value, type } = event.target;
        if(type === 'checkbox'){
            setFiltro({ ...filtro, [name]: event.target.checked });
        }else{
            setFiltro({ ...filtro, [name]: value });
        }
    };

    const filteredData = tabla.filter((item) => {
        const nombre = item.cliente.toLowerCase().includes( filter.toLowerCase());
        const tipoCredito = item.tipo_credito.toLowerCase().includes(filter.toLowerCase());
        const numero_documento = item.numero_documento.toLowerCase().includes(filter.toLowerCase());
        const idMatch = item.id_credito.toString().includes(filter);
        return nombre || tipoCredito || numero_documento || idMatch;
    });

    const handleConsultarCreditos = async () => {
        
        if(filtro.fechas){
            if(filtro.fecha_ini.length === 0){
                toast('La fecha inicial no es valida','',true);
                return;
            }

            if(filtro.fecha_fin.length === 0){
                toast('La fecha final no es valida','',true);
                return;
            }
        }

        setSpiner(true)

        try {
            await creditos(filtro.fechas, filtro.fecha_ini, filtro.fecha_fin, filtro.estado_id).then(response => {
                if(response.status === 200){
                    setTabla(response.data.data);
                }else{
                    alerta('Error al cargar la lista de creditos','Por favor intente nuevamente')
                }
            });
         }catch (error) {
            const errorMessage = error.message || 'Error desconocido';
            alerta('Error al cargar la lista de créditos', `Por favor intente nuevamente. Detalles del error: ${errorMessage}`);
        }

        setSpiner(false)
    }

    return(
        <React.Fragment>
            <div className='container-creditos'>
                <div className='row'>
                    <div className='col-md-2'>
                        <div className="form-check form-switch mt-3">
                            <br></br>
                            <input className="form-check-input" type="checkbox" role="switch" id="fechas" name="fechas" checked={filtro.fechas} onChange={handleInputChange} />
                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Filtrar por fechas</label>
                        </div>
                    </div>
                    {filtro.fechas ?
                    <>
                        <div className='col-md-2'>
                            <label className="mb-1">Desde:</label>
                            <div className="input-group">
                                <input 
                                    type="date" 
                                    className="form-control" 
                                    id="fecha_ini" 
                                    name="fecha_ini" 
                                    value={filtro.fecha_ini}
                                    onChange={handleInputChange} 
                                />
                            </div>
                        </div>
                        <div className='col-md-2'>
                            <label className="mb-1">Hasta:</label>
                            <div className="input-group">
                                <input 
                                    type="date" 
                                    className="form-control" 
                                    id="fecha_fin" 
                                    name="fecha_fin" 
                                    value={filtro.fecha_fin}
                                    onChange={handleInputChange} 
                                />
                            </div>
                        </div>
                    </>
                    :''}
                    <div className='col-md-2'>
                        <label className="form-label" htmlFor="estado_id">Estado</label>
                        <select className="form-select form-select-sm" name="estado_id" value={filtro.estado_id} onChange={handleInputChange}>
                            <option value="">Todos</option>
                            <option value="1">Activos</option>
                            <option value="3">En mora</option>
                            <option value="4">Pagados</option>
                            <option value="13">Anulados</option>
                        </select>
                    </div>
                    
                    {tabla.length > 0 ?
                    <div className='col-md-3'>
                        <label className="mb-1">&nbsp;</label>
                        <div className="input-group mt-1">
                            <input type="text" className="form-control form-control-sm" placeholder="Buscar..." aria-label="Buscar" aria-describedby="basic-addon1" value={filter}
                            onChange={(e) => setFilter(e.target.value)} />
                        </div>
                    </div>:''}               
                    
                    <div className="col-md-1">
                        <label className='mb-2'>&emsp;</label>
                        <div className="input-group">
                            <button 
                                type="button" 
                                className="btn btn-outline-primary btn-sm"
                                onClick={handleConsultarCreditos}
                                disabled={spiner}
                            >
                                Buscar
                            </button>
                        </div>
                    </div>
                </div>
                <br></br>
                {spiner ?
                <div className="d-flex align-items-center">
                    <strong role="status" className='text-secondary'>Cargando Informacion...</strong>
                    <div className="spinner-border ms-auto text-secondary" aria-hidden="true"></div>
                </div>:''}
                <div className="table-responsive">
                    {tabla.length > 0 ?
                        <table className="table table-hover table-sm text-start" style={{fontSize:'12px'}}>
                            <thead>
                                <tr>
                                    <th className='text-center'>Codigo</th>
                                    <th className='text-center'>Tipo</th>
                                    {props.usuario.perfil_id == 2 ?<th>Token</th>:''}
                                    <th>Creado</th>
                                    <th>Último Pago</th>
                                    <th>Documento</th>
                                    <th>Cliente</th>
                                    <th>Valor Crédito</th>
                                    <th>Saldo</th>
                                    <th>Valor Cuota</th>                                    
                                    <th className='text-center'>Dia Pago</th>                                  
                                    <th className='text-center'>Dias Mora</th>
                                    <th  className='text-center'>Estado</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(tabla) ? filteredData.map((item, index) => (
                                <tr key={index} className='align-middle'>
                                    <td className='text-center'><span className="badge text-bg-info"><i className="bi bi-upc"></i> {item.id_credito}</span></td>
                                    <td className={item.tipo_credito === 'COMPUESTO' ? 'text-info text-center': 'text-primary text-center'}>
                                        <b>{item.tipo_credito}</b>
                                    </td>
                                    {props.usuario.perfil_id == 2 ?
                                        <td>
                                            <button 
                                                type="button" 
                                                className="btn btn-outline-warning btn-sm"
                                                data-bs-toggle="modal" 
                                                data-bs-target="#modalGenerarToken"
                                                onClick={() => props.setIdCredito(item.id_credito)}
                                            >
                                                <i className="bi bi-shield-lock mx-1"></i>                                                
                                            </button>
                                        </td>
                                    :''}
                                    <td>
                                        <i className="bi bi-calendar3"></i> {item.fecha}                                        
                                    </td>
                                    <td>
                                        <i className="bi bi-calendar3"></i> {item.fecha_ultimo_pago}                                        
                                    </td>
                                    <td>{item.numero_documento}</td>
                                    <td>
                                        <div className='d-flex  align-items-center'>
                                            <img width="20" className="rounded-circle mx-2" src={item.sexo == 'M'? avatar_men : avatar_gril } alt="" />
                                            {item.cliente}
                                        </div>
                                    </td>                                    
                                    <td> ${formatearMonedaValue(item.valor)}</td>
                                    <td> ${formatearMonedaValue(item.saldo_actual)}</td>
                                    <td>${formatearMonedaValue(item.cuota_mensual)}</td>                                    
                                    <td className='text-center'>{item.dia_de_pago}</td>
                                    <td className='text-center'>{item.dias_mora}</td>                                    
                                    <td>
                                        {item.estado_id === 4 ?
                                            <span className="badge text-bg-success">{item.estado}</span>
                                            : item.estado_id === 3 || item.estado_id === 13 ?
                                            <span className="badge text-bg-danger">{item.estado}</span>
                                            :
                                            <span className="badge text-bg-warning">{item.estado}</span>
                                        }
                                    </td> 
                                    <td>
                                        <button 
                                            type="button" 
                                            className="btn btn-outline-primary btn-sm"
                                            onClick={() => handleBuscarIdCredito(item.id_credito)}
                                        >
                                            <i className="bi bi-clipboard-check mx-1"></i>
                                            Crédito
                                        </button>
                                    </td>                                   
                                    <td>
                                        <button 
                                            type="button" 
                                            className="btn btn-outline-dark btn-sm"
                                            data-bs-toggle="modal" 
                                            data-bs-target="#modalGestionCredito"
                                            onClick={() => props.setIdCredito(item.id_credito)}
                                        >
                                            <i className="bi bi-currency-dollar"></i>
                                            Pagar
                                        </button>
                                    </td>                                    
                                </tr>
                                )) : <tr></tr>}
                            </tbody>
                        </table>
                    :
                        <div className='text-center text-secondary my-4 align-items-center'>
                            <h3>No se encontraron resultados.</h3>
                            <p>Por favor, ajuste los filtros de búsqueda e intente nuevamente.</p>
                        </div>
                    }
                </div>
            </div>
            <ModalGenerarToken></ModalGenerarToken>
        </React.Fragment>
    );
}

const mapDispatchToProps  = {
    setSaldosEmpresa,
    setIdCredito
};

const mapSateToProps = state => {

    return {
        usuario: state.usuario
    }
}

export default connect(mapSateToProps, mapDispatchToProps)(Creditos)